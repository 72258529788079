import { useState } from 'react'
import pen from '../../assets/images/pen.svg'

import './index.css'
import Rank from '../Rank'
import { getIdFromRank, getRankFromId, getSupply } from '../../api/api'


function Main() {
  const [activeTabs, setActiveTabs] = useState<string>("rank")
  const [rank, setRank] = useState<number>(1)
  const [id, setId] = useState<number>(getIdFromRank(1))
  const supply = getSupply();

  const onChangeId = (newId: number) => {
    setId(newId)
    setRank(getRankFromId(newId))
  }

  const onChangeRank = (newRank: number) => {
    setRank(newRank)
    setId(getIdFromRank(newRank))
  }

  return(
    
    <div className='rarity'>
      <div className='row'>
        <div className='card-tabs'>
          <div className='tabs'>

          
          </div>
          { activeTabs === "rank" &&
            <div style={{display: "flex"}}>
              <div className='input-group'>
                <div className='input-text'><div className='white'>id</div></div>
                <div className='input-d'> 
                  
                  <input type="number" min="0" max={supply-1} style={{width: "60px"}} value={id} onChange={(e) => onChangeId(e.target.valueAsNumber)}/>
                </div>
              </div>
              <div className='input-group' style={{marginLeft: "10px"}}>
                <div className='input-text'><div className='white'>rank</div></div>
                <div className='input-d'>
                 
                  <input type="number" min="1" max={supply} style={{width: "60px"}} value={rank} onChange={(e) => onChangeRank(e.target.valueAsNumber)} />
                </div>
              </div>
            </div>
          }
        </div>
        { activeTabs === "rank" &&
          <Rank id={id} rank={rank} />
        }
        
      </div>
    </div>
  )
}

export default Main