import './App.css';
import Tiers from './components/Tiers';
import Main from './components/Main';
import { useState } from 'react';
import { getAttributes, getSupply } from './api/api';

function App() {
  const [attributes, setAttributes] = useState<number>(getAttributes())
  const [supply, setSupply] = useState<number>(getSupply())
  return (
    <div className="App container desktop">
      <div className="Header">
      <a className="Header-logo" href="https://y32ts.com">
        <img  width='180px' src={'./banner.png'}></img>
        </a>
      <h1 className='container-header' >  y32ts Rarity Tool </h1>
 
      </div>
      <Tiers attributes={attributes} supply={supply} />
      <Main />      
    </div>
  );
}

export default App;
