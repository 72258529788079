import { useEffect, useState } from 'react'
import solscan from '../../assets/images/solscan.svg'
import magiceden from '../../assets/images/magiceden.svg'

import './index.css'
import { getMetadatasFromId } from '../../api/api'

function Rank(
  props: {
    id: number,
    rank: number,
  }
) {
  const [metadata, setMetadata] = useState<any>(null)
  useEffect(() => {
    if(props.rank !== 0)
      setMetadata(getMetadatasFromId(props.id))
  }, [props.id])
  return(
    <>
      { metadata !== null &&
      <div className='rarity-exploer'>
        <div className='viverorc' style={{backgroundImage: `url(${metadata.image})`}}>
          <div className="viveinfo">
            <div>y32t</div>
            <div>#{props.id}</div>
          </div>
          
        </div>
        <div className='rarity-exploer-check'>
          <div className='rarity-list'>
            <div className='rarity-item rarity-item-top'>
              <div className='rarity-top'>
                <label >Score</label>
                <div className={`helper-${metadata.tier}`}>
                  <span style={{textTransform: "uppercase"}}>{metadata.tier}</span>
                  <span className='num'>{metadata.average} </span>
                </div>
              </div>
              <div className='rarity-bottom'>
                <div className={`progress-bar progress-rarity progress-${metadata.tier}`}>
                  <span style={{width: `${metadata.average}%`}}></span>
                </div>
              </div>
            </div>
           
            {
              metadata.traitsValue.map((item: any, index: number) => (
                <div className='rarity-item rarity-item-top' key={index}>
                  <div className='rarity-top'>
                    <div>
                      <label style={{width: "120px", display: "inline-block"}}>{item.trait_type}</label>
                      <div><b style={{color: "black", fontSize: "14px"}}>{item.value}</b></div>
                    </div>
                    <div>
                      <span className='helper'>{ item.percentage } %</span>
                    </div>
                  </div>
                  <div className='rarity-bottom'>
                    <div className='progress-bar progress-rarity progress-light'>
                      <span style={{width: `${item.percentage}%`}}></span>
                    </div>
                  </div>
                </div>
                )
              )
            }
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default Rank