import { isTemplateExpression } from "typescript"

const traits = require('./datas/traits.json')
const metadatas = require('./datas/metadatas.json')
const ranks = require('./datas/rank.json')

export const getAttributes = () => {
  let attributes: number = 0
  for(let i = 0; i < traits.length; i ++)
    attributes += traits[i].length
  return attributes
}

export const getSupply = () => {
  return metadatas.length
}

export const getRankFromId = (
  id: number
) => {
  return ranks.findIndex((item: {id: number}) => item.id == id) + 1
}

export const getIdFromRank = (
  rank: number
) => {
  return ranks[rank - 1].id
}

export const getMetadatasFromId = (
  id: number
) => {
  let { metadata, mintKey } = metadatas.find((item: {id: number}) => item.id == id)
  let traitsValue = []
  for(let i = 0; i < metadata.attributes.length; i ++) {
    let isFound = false
    for(let j = 0; j < traits[i].length; j ++) {
      if(metadata.attributes[i].trait_type === traits[i][j].trait_type){
        if(metadata.attributes[i].value === traits[i][j].value) {
          traitsValue.push({
            trait_type: metadata.attributes[i].trait_type,
            value: metadata.attributes[i].value,
            rar: traits[i][j].amount,
            percentage: 0
          })
          isFound = true
        } else if(j === traits[i].length - 1 && !isFound) {
          traitsValue.push({
            trait_type: metadata.attributes[i].trait_type,
            value: metadata.attributes[i].value,
            rar: 1,
            percentage: 0
          })
        }
      } else if(j === traits[i].length -1 && !isFound){
        traitsValue.push({
          trait_type: metadata.attributes[i].trait_type,
          value: metadata.attributes[i].value,
          rar: 1,
          percentage: 0
        })
      }
    }
  }

  let r = ranks.findIndex((item: {id: number}) => item.id == id) + 1
  let tier = r <= Math.ceil(metadatas.length / 100) ? "mythic" : 
            r <= Math.ceil(metadatas.length * 5 / 100)? "legendary" : 
            r <= Math.ceil(metadatas.length * 15 / 100) ? "epic" : 
            r <= Math.ceil(metadatas.length * 35 / 100) ? "rare": 
            r <= Math.ceil(metadatas.length * 60 / 100) ? "uncommon" : "common"
  let average = 0
  for(let i = 0; i < traitsValue.length; i ++) {
    
    traitsValue[i].percentage = parseFloat((traitsValue[i].rar * 100 / metadatas.length).toFixed(2))
  }
  console.log(traitsValue)

  average = (5500-r) / 11
  return { image: metadata.image, traitsValue: [...traitsValue], tier: tier, mintKey, average: (average / traitsValue.length).toFixed(2) }
}